@font-face {
	font-family: "calciomercato";
	font-display: block;
	src: url("https://assets.prod.calciomercato.com/build/app.calciomercato.ad7e5d46fa48eb139791e7505fb0b1ad.eot?#iefix") format("embedded-opentype"),
url("https://assets.prod.calciomercato.com/build/app.calciomercato.e3012149a0b283f288a61fefb4e480ca.woff") format("woff"),
url("https://assets.prod.calciomercato.com/build/app.calciomercato.01f89856e9727aa035c35a229c158500.woff2") format("woff2"),
url("https://assets.prod.calciomercato.com/build/app.calciomercato.588def7d094d28ef3074b683eeda0a38.ttf") format("truetype"),
url("https://assets.prod.calciomercato.com/build/app.calciomercato.ac798a8aa652736851d90460b52ee29f.svg#calciomercato") format("svg");
}

.cm-ico {
	line-height: 1;
}

.cm-ico:before {
	font-family: calciomercato !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.cm-arrow:before {
	content: "\f101";
}
.cm-clock:before {
	content: "\f102";
}
.cm-close:before {
	content: "\f103";
}
.cm-comment:before {
	content: "\f104";
}
.cm-external-link:before {
	content: "\f105";
}
.cm-facebook:before {
	content: "\f106";
}
.cm-home:before {
	content: "\f107";
}
.cm-like:before {
	content: "\f108";
}
.cm-linkedin:before {
	content: "\f109";
}
.cm-loader:before {
	content: "\f10a";
}
.cm-login:before {
	content: "\f10b";
}
.cm-messenger:before {
	content: "\f10c";
}
.cm-newsletter:before {
	content: "\f10d";
}
.cm-notification:before {
	content: "\f10e";
}
.cm-play:before {
	content: "\f10f";
}
.cm-preference:before {
	content: "\f110";
}
.cm-refresh:before {
	content: "\f111";
}
.cm-search:before {
	content: "\f112";
}
.cm-share:before {
	content: "\f113";
}
.cm-twitter:before {
	content: "\f114";
}
.cm-whatsapp:before {
	content: "\f115";
}
.cm-win:before {
	content: "\f116";
}
.cm-x:before {
	content: "\f117";
}
